import './modules/register-service-worker';
import 'alpinejs';

// Admin styles / scripts on when needed
if (document.body.classList.contains('is-admin')) {
  import('./modules/admin');
}

// Controllers (Stimulusjs)
if (document.querySelector('[data-controller]:not(.stock-chart)')) {
  import('./modules/controllers');
}

// Cookie consent
if (document.querySelector('html[lang="zh"]')) {
  import('./modules/cookie-popup-zh.js');
} else {
  import('./modules/cookie-popup.js');
}
