if ('serviceWorker' in navigator) {
  if (process.env.NODE_ENV === 'production' || process.env.SW_DEV) {
    const serviceWorkerPath = `${process.env.PUBLIC_PATH}service-worker.js`;
    navigator.serviceWorker
      .register(serviceWorkerPath, { scope: '/' })
      .then(function() {
        // registration worked
        console.log('Service worker registered.');
      })
      .catch(function(error) {
        // If there is (probably) something wrong with Service Worker path
        if (error instanceof TypeError) {
          // registration failed
          console.log(
            `Service worker registration failed. Invalid Service Worker path? (${serviceWorkerPath})`
          );
        }
      });
  }
}
